import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import {RadiusUpleftOutlined} from '@ant-design/icons'
import {Typography} from '@mui/material'
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //
import React from 'react';

const LogoMain = React.memo(() => {
  const theme = useTheme();

  return (
    <>
      <RadiusUpleftOutlined style={{fontSize:32, color:theme.palette.success.main}} />
      <Typography variant='h3' component="span" ml={1}>Askpot</Typography>
    </>
  );
});



LogoMain.propTypes = {
  reverse: PropTypes.bool
};

export default LogoMain;
