import { SET_SWOT_RESULT } from './actions';

const initialState = {
    swot: null,
};

const swotReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SWOT_RESULT:
      return {
        ...state,
        swot: action.data
      };
    
    
    default:
      return state;
    }
};

export default swotReducer;