

// material-ui
import { useTheme } from '@mui/material/styles';
// import { useState } from 'react';
// import useConfig from 'hooks/useConfig';

import IconButton from 'components/@extended/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { openDrawer } from 'store/reducers/menu';

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import Profile from 'layout/MainLayout/Header/HeaderContent/Profile';
// import MobileSection from 'layout/MainLayout/Header/HeaderContent/MobileSection';

// material-ui
import { Button, Stack, Typography, useMediaQuery, List, ListItemButton, ListItem,Box  } from '@mui/material';

// project import

// assets
// import AnimateButton from 'components/@extended/AnimateButton';
import CircularWithLabel from 'components/@extended/progress/CircularWithLabel';
// import useAuth from 'hooks/useAuth';

// import { useNavigate } from 'react-router-dom';


const NavButton = () => {
    // const { logout } = useAuth();
    // const navigate = useNavigate();

    // const { miniDrawer } = useConfig();
    const menu = useSelector((state) => state.menu);
    const accountDetails = useSelector((state) => state.proAccountReducer);

    const dispatch = useDispatch();
    const { drawerOpen } = menu;
    const theme = useTheme();
    // const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';
    const iconBackColor = theme.palette.mode === 'dark' ? 'background.default' : 'grey.100';
    // const [open, setOpen] = useState(!miniDrawer || drawerOpen);
    // const matchesMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const handleDrawerToggle = () => {
        dispatch(openDrawer({ drawerOpen: !drawerOpen }));
      };

      const handleEmailButtonClick = () => {
        window.location.href = 'mailto:info@askpot.com?subject=Support:';
      };

    return (
        

       
            
        <List sx={{  pl: drawerOpen ? 0 :1.5,   width: drawerOpen ? '200px' : 'auto'}} dense>

            
                {!matchesXs &&
                (!drawerOpen ? 
                    <Stack direction='column' spacing={1} sx={{mb:1}}>
                    <IconButton
                            aria-label="open drawer"
                            onClick={handleDrawerToggle}
                            edge="start"
                            color="secondary"
                            size='medium'
                            variant="light"
                            sx={{ color: 'text.primary', bgcolor: iconBackColor, ml: 0 , my:2}}
                        >
                        <MenuUnfoldOutlined /> 
                    </IconButton>
                    <IconButton
                        aria-label="support"
                        onClick={handleEmailButtonClick}
                        edge="start"
                        color="secondary"
                        size='medium'
                        variant="light"
                        sx={{ color: 'text.primary',  ml: 0 , my:2}}
                    >
                        <QuestionCircleOutlined /> 
                    </IconButton>
                    </Stack>
                    : 
                    <>
                    <ListItemButton
                        aria-label="open drawer"
                        startIcon={<MenuFoldOutlined />}
                        onClick={handleDrawerToggle}
                        // edge="start"
                        color="text.secondary"
                        variant="light"
                        sx={{
                            my:1,
                            pl:3,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'inherit',

                          }}
                        > <MenuFoldOutlined />
                        <Typography variant='body1' ml={2}>
                            Collapse
                        </Typography>
                    </ListItemButton>
                   <ListItemButton
                        aria-label="support"
                        startIcon={<QuestionCircleOutlined />}
                        onClick={handleEmailButtonClick}
                        // edge="start"
                        color="text.secondary"
                        variant="light"
                        sx={{
                            my:1,
                            pl:3,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'inherit',

                          }}
                        > <QuestionCircleOutlined />
                        <Typography variant='body1' ml={2}>
                            Support
                        </Typography>
                    </ListItemButton>

                    </>
                    
                    )
                }
          
            
            {drawerOpen && accountDetails ?
                <>
                    
                    <ListItemButton  target='_blank' href={'/pricing'} >
                        <Stack direction='row' spacing={2} >
                            <CircularWithLabel size={34} value={Math.round(((accountDetails.monthly_credits - accountDetails.remaining_credits) / accountDetails.monthly_credits*100))} color="primary" />
                            <Stack direction='column'>
                                <Typography variant='body2'>
                                    {accountDetails.remaining_credits} Credits 
                                </Typography>
                                <Typography variant='body2' color='secondary' mt={0}>
                                    {accountDetails.plan + ' Plan'}
                                </Typography>
                            </Stack> 
                        </Stack> 
                    </ListItemButton>
                    {accountDetails.plan === 'Free' ?      
                    <ListItem  divider>
                        <Button 
                        
                        variant='contained'  size='small' fullWidth target='_blank' href={'/pricing'}  
                        sx={{
                            mb:1, 
                            mt:0,
                            // '&:hover': {
                            //     bgcolor: 'primary.light'
                            //   },
                            
                        }}> 
                        Upgrade Plan
                        </Button>
                       
                    </ListItem>
                    : 
                    null
                    }
                
                </>
                :
                null}

                

            <Box pl={drawerOpen ? 2:0}>
                {!matchesXs && <Profile/>}
            </Box>
          
            </List>
      
      
    )
}

export default NavButton;