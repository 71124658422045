import PropTypes from 'prop-types';
import { lazy, Suspense, useState, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Toolbar, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { openComponentDrawer } from 'store/reducers/menu';
import Header from './Header';

const FooterBlock = lazy(() => import('./FooterBlock'));

// ==============================|| Loader ||============================== //

const LoaderWrapper = styled('div')(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 2001,
  width: '100%',
  '& > * + *': {
    marginTop: theme.spacing(2)
  }
}));

const Loader = () => (
  <LoaderWrapper>
    <LinearProgress color="primary" />
  </LoaderWrapper>
);

// ==============================|| MINIMAL LAYOUT ||============================== //

const CommonLayout = ({ layout = 'blank' }) => {
  const dispatch = useDispatch();
  const menu = useSelector((state) => state.menu);
  const { componentDrawerOpen } = menu;

  const [isContentLoaded, setIsContentLoaded] = useState(false);
  const location = useLocation();

  const handleDrawerOpen = () => {
    dispatch(openComponentDrawer({ componentDrawerOpen: !componentDrawerOpen }));
  };

  useEffect(() => {
    setIsContentLoaded(false);
    const timer = setTimeout(() => {
      setIsContentLoaded(true);
    }, 0); // Set to 0 to ensure it runs after the next render cycle

    return () => clearTimeout(timer); // Cleanup the timer
  }, [location]);

  return (
    <Suspense fallback={<Loader />}>
      {layout === 'landing' && (
        <>
          <Header layout={layout} />
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
          {isContentLoaded && (
            <Suspense fallback={null}>
              <FooterBlock isFull={true} />
            </Suspense>
          )}
        </>
      )}
      {layout === 'simple' && (
        <>
          <Header layout={layout} />
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </>
      )}
      {layout === 'component' && (
        <Container maxWidth="lg">
          <Header handleDrawerOpen={handleDrawerOpen} layout="component" />
          <Toolbar />
        </Container>
      )}
      {layout === 'blank' && <Outlet />}
    </Suspense>
  );
};

CommonLayout.propTypes = {
  layout: PropTypes.string
};

export default CommonLayout;