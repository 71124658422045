import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import ThankYou from 'pages/thank-you';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
// const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page

const LpComparer = Loadable(lazy(() => import('pages/lp-comparer')));
const LpAnalysis = Loadable(lazy(() => import('pages/lp-analysis')));
const ProPage = Loadable(lazy(() => import('pages/pro-page')));
const CompetitorAnalysisList = Loadable(lazy(() => import('pages/competitors-analysis-list')));


// render - applications

const UserProfile = Loadable(lazy(() => import('pages/apps/profiles/user')));
// const UserTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/user/TabPersonal')));
const UserTabPayment = Loadable(lazy(() => import('sections/apps/profiles/user/TabPayment')));
// const UserTabPassword = Loadable(lazy(() => import('sections/apps/profiles/user/TabPassword')));
// const AccountTabRole = Loadable(lazy(() => import('sections/apps/profiles/account/TabRole')));
const UserTabSettings = Loadable(lazy(() => import('sections/apps/profiles/user/TabSettings')));

const TermsOfUse = Loadable(lazy(() => import('pages/maintenance/TermsOfUse')));

const PricingPage = Loadable(lazy(() => import('pages/extra-pages/pricing')));
const PricingAlpha = Loadable(lazy(() => import('pages/extra-pages/pricingAlpha')));

const PremiumRequest = Loadable(lazy(() => import('pages/extra-pages/premium-request')));

const CompanyProfile = Loadable(lazy(() => import('pages/company-profile')));
const CompanyDirectory = Loadable(lazy(() => import('pages/company-directory')));

const Reviews = Loadable(lazy(() => import('pages/reviews')));





// const AccountProfile = Loadable(lazy(() => import('pages/apps/profiles/account')));
// const AccountTabProfile = Loadable(lazy(() => import('sections/apps/profiles/account/TabProfile')));
// const AccountTabPersonal = Loadable(lazy(() => import('sections/apps/profiles/account/TabPersonal')));
// const AccountTabAccount = Loadable(lazy(() => import('sections/apps/profiles/account/TabAccount')));
// const AccountTabPassword = Loadable(lazy(() => import('sections/apps/profiles/account/TabPassword')));
// const AccountTabSettings = Loadable(lazy(() => import('sections/apps/profiles/account/TabSettings')));
// pages routing
// const AuthLogin = Loadable(lazy(() => import('pages/auth/login')));
// const AuthRegister = Loadable(lazy(() => import('pages/auth/register')));
// const AuthForgotPassword = Loadable(lazy(() => import('pages/auth/forgot-password')));
// const AuthResetPassword = Loadable(lazy(() => import('pages/auth/reset-password')));
// const AuthCheckMail = Loadable(lazy(() => import('pages/auth/check-mail')));
// const AuthCodeVerification = Loadable(lazy(() => import('pages/auth/code-verification')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        // {
        //   path: 'sample-page',
        //   element: <SamplePage />
        // },
        // {
        //   path: 'main',
        //   element: <AskpotChat />
        // },

        {
          path: 'lp',
          element: <LpComparer/>
        },
        {
          path: 'lp-analysis',
          element: <LpAnalysis/>
        },
        {
          path: 'pricing',
          element: <PricingPage />
        },
        {
          path: 'specpricing',
          element: <PricingAlpha />
        },
        {
          path: 'request-alpha',
          element: <PremiumRequest />
        },
        {
          path: 'pro',
          element: <ProPage />
        },
        {
          path: 'competitors-history',
          element: <CompetitorAnalysisList/>
        },
        {
          path: 'reviews',
          element: <Reviews/>
        },
        {
          path: 'profiles',
          children: [
            // {
            //   path: 'account',
            //   element: <AccountProfile />,
            //   children: [
            //     {
            //       path: 'basic',
            //       element: <AccountTabProfile />
            //     },
            //     {
            //       path: 'personal',
            //       element: <AccountTabPersonal />
            //     },
            //     {
            //       path: 'my-account',
            //       element: <AccountTabAccount />
            //     },
            //     {
            //       path: 'password',
            //       element: <AccountTabPassword />
            //     },
            //     {
            //       path: 'role',
            //       element: <AccountTabRole />
            //     },
            //     {
            //       path: 'settings',
            //       element: <AccountTabSettings />
            //     }
            //   ]
            // },
            {
              path: 'user',
              element: <UserProfile />,
              children: [
                // {
                //   path: 'personal',
                //   element: <UserTabPersonal />
                // },
                {
                  path: 'payment',
                  element: <UserTabPayment />
                },
                // {
                //   path: 'password',
                //   element: <UserTabPassword />
                // },
                {
                  path: 'settings',
                  element: <UserTabSettings />
                }
              ]
            }
          ]
        },
      ]
    },

    
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: 'terms',
          element: <TermsOfUse />
        },
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        // {
        //   path: 'under-construction',
        //   element: <MaintenanceUnderConstruction />
        // },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        }
      ]
    },
    {
      path: '/directory',
      element: <CommonLayout layout='simple'/>,
      children: [
        {
          path: '/directory',
          element: <CompanyDirectory />
        },
        {
          path: ':companyName/*', // Use a wildcard to match subpaths
          // path: ':companyName',
          element: <CompanyProfile />,
          // children: [
          //   { path: '/', element: <TabPanel index={0} /> },
          //   { path: 'business-analysis', element: <TabPanel index={1} /> },
          //   { path: 'audience', element: <TabPanel index={2} /> },
          //   { path: 'unique-selling-points', element: <TabPanel index={3} /> },
          //   { path: 'swot', element: <TabPanel index={4} /> },
          //   { path: 'market-overview', element: <TabPanel index={5} /> },



          //   // Add more routes for each tab
          // ]
        },
      ]
    
    },
    {
      path: 'thank-you',
      element: <ThankYou />
    }
  ]
};

export default MainRoutes;
