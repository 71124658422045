import React, { useEffect, useState } from 'react';
import { Typography, Box, CircularProgress, Button } from '@mui/material';
import MainCard from 'components/MainCard';
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    // Fire Google Ads conversion event
    if (window.gtag) {
      window.gtag('event', 'conversion', {
        'send_to': 'AW-16481265161/ta2oCNK7_9QZEInM8LI9',
        'value': 79,
        'currency': 'EUR',
        'transaction_id': ''
      });
    }

    // Show button after 6 seconds (1 second after expected redirect)
    const buttonTimer = setTimeout(() => {
      setShowButton(true);
    }, 6000);

    // Redirect after 5 seconds
    const redirectTimer = setTimeout(() => {
      navigate('/lp-analysis');
    }, 5000);

    return () => {
      clearTimeout(redirectTimer);
      clearTimeout(buttonTimer);
    };
  }, [navigate]);

  const handleManualRedirect = () => {
    navigate('/lp-analysis');
  };

  return (
    <MainCard>
      <Box sx={{ textAlign: 'center', py: 5 }}>
        <Typography variant="h3" gutterBottom>
          Thank you for your purchase!
        </Typography>
        <Typography variant="body1" paragraph>
          You&apos;ll be redirected to the platform shortly.
        </Typography>
        <CircularProgress />
        {showButton && (
          <Box mt={3}>
            <Button variant="contained" color="primary" onClick={handleManualRedirect}>
              Go to Platform
            </Button>
          </Box>
        )}
      </Box>
    </MainCard>
  );
};

export default ThankYou;