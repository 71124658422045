import { OPEN_MODAL, CLOSE_MODAL } from './actions';

const initialState = {
  isOpen: false,
  modalData: null,
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,
        isOpen: true,
        modalData: action.payload,

      };
    case CLOSE_MODAL:
      return {
        ...state,
        isOpen: false,
        modalData: null,
      };
    default:
      return state;
  }
};

export default modalReducer;