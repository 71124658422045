
import { ADD_PARAMETER_TO_ALL, DELETE_PARAMETER_FROM_ALL } from './actions';


const initialState = {
  parameters: [

  ],
};


  const allComparisonParamsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_PARAMETER_TO_ALL:
        return {
          ...state,
          parameters: [...state.parameters, action.payload],
        };
  
      case DELETE_PARAMETER_FROM_ALL:
        return {
          ...state,
          parameters: state.parameters.filter(
            (param) => param.Parameter !== action.payload.Parameter
          ),
        };
  
      default:
        return state;
    }
  };

  export default allComparisonParamsReducer;