import PropTypes from 'prop-types';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

// material-ui
import AppBar from '@mui/material/AppBar';
import { useTheme } from '@mui/material/styles';
import {
  useMediaQuery,
  Box,
  Button,
  Container,
  Drawer,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Typography,
  useScrollTrigger
} from '@mui/material';

// project import
import { APP_DEFAULT_PATH } from 'config';
import IconButton from 'components/@extended/IconButton';
import AnimateButton from 'components/@extended/AnimateButton';
import Logo from 'components/logo';

import useAuth from 'hooks/useAuth';

import { getAuth } from 'firebase/auth';



// assets
import { MenuOutlined, LineOutlined } from '@ant-design/icons';

// ==============================|| COMPONENTS - APP BAR ||============================== //

// elevation scroll
function ElevationScroll({ layout, children, window }) {
  const theme = useTheme();
  // const theme = useTheme();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 10,
    target: window ? window() : undefined
  });

  const backColorScroll = theme.palette.mode === 'dark' ? theme.palette.grey[50] : theme.palette.grey[50];
  const backColor = layout !== 'landing' ? backColorScroll : 'transparent';

  return React.cloneElement(children, {
    style: {
      backgroundColor: trigger ? backColorScroll : backColor
    }
  });
}

const Header = ({ handleDrawerOpen, layout = 'landing', ...others }) => {
  const theme = useTheme();
  const auth = getAuth();

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  // const matchDownLG = useMediaQuery(theme.breakpoints.down('lg'));

  
  const [drawerToggle, setDrawerToggle] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setIsLoggedIn(!!user); // !!user converts user to a boolean value
    });

    // Cleanup function to unsubscribe from the auth state listener
    return () => unsubscribe();
  }, []); // Empty dependency array means this effect runs once after the initial render

  const { 
    // firebaseFacebookSignIn, 
    firebaseGoogleSignIn, 
    // firebaseTwitterSignIn 
  } = useAuth();

  const navigate = useNavigate();
  const googleHandler = async () => {
    
    try {
      await firebaseGoogleSignIn();
      navigate("/lp-analysis");
    } catch (err) {
      console.error(err);
    }
  };



  /** Method called on multiple components with different event types */
  const drawerToggler = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerToggle(open);
  };

  return (
    <ElevationScroll layout={layout} {...others}>
      <AppBar sx={{ bgcolor: 'transparent', color: theme.palette.text.primary, boxShadow: 'none', }}>
        <Container maxWidth="xl" disableGutters={matchDownMd}>
          <Toolbar sx={{ width: '100%', px: { xs: 1.5, md: 0, lg: 0 }, py: 2,}}>
            <Stack direction="row" sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' },  }} alignItems="center">
              <Typography component="div" sx={{ textAlign: 'left', display: 'inline-block' }}>
                <Logo reverse to="/" />
              </Typography>
              
            </Stack>
            <Stack
              direction="row"
              sx={{
                '& .header-link': { px: 1, '&:hover': { color: theme.palette.primary.main } },
                display: { xs: 'none', md: 'block'}
              }}
              spacing={2}
            >
              {/* <Link className="header-link" color="textPrimary" component={RouterLink} to="#features"  underline="none">
                Features
              </Link> */}
              {/* <Link className="header-link" color="textPrimary" component={RouterLink} to="/login" target="_blank" underline="none">
                Directory
              </Link> */}
              {/* <Link
                className="header-link"
                color={handleDrawerOpen ? 'textPrimary' : 'textPrimary'}
                component={RouterLink}
                to="https://blog.askpot.com"
                underline="none"
              >
                Blog
              </Link> */}
              <Link className="header-link" color="textPrimary" href='/pricing' underline="none">
                Pricing
              </Link>
              <Box sx={{ display: 'inline-block' }}>
                {!isLoggedIn ?
                (<AnimateButton>
                <Button
                  component={Link}
                  onClick={googleHandler}
                  disableElevation
                  color="primary"
                  variant="contained"
                >
                  Sign In
                </Button>
              </AnimateButton>)
                :
                (<AnimateButton>
                  <Button
                    component={Link}
                    href='/lp-analysis'
                    disableElevation
                    color="primary"
                    variant="contained"
                  >
                    go To Platform
                  </Button>
                </AnimateButton>)
                }
                
              </Box>
            </Stack>
            <Box
              sx={{
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
                display: { xs: 'flex', md: 'none' },
                
              }}
            >
              <Typography component="div" sx={{ textAlign: 'left', display: 'inline-block' }}>
                <Logo reverse to="/" />
              </Typography>
              <Stack direction="row" spacing={2}>
                {layout === 'component' && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="warning"
                    component={RouterLink}
                    to={APP_DEFAULT_PATH}
                    sx={{ mt: 0.5, height: 28 }}
                  >
                    Dashboard
                  </Button>
                )}


                <IconButton
                  color="secondary"
                  {...(layout === 'component' ? { onClick: handleDrawerOpen } : { onClick: drawerToggler(true) })}
                  sx={{
                    '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary' : 'secondary.dark' }
                  }}
                >
                  <MenuOutlined style={{ color: theme.palette.mode === 'dark' ? 'inherit' : theme.palette.grey[900] }} />
                </IconButton>
              </Stack>
              <Drawer
                anchor="top"
                open={drawerToggle}
                onClose={drawerToggler(false)}
                sx={{ '& .MuiDrawer-paper': { backgroundImage: 'none' } }}
              >
                <Box
                  sx={{
                    width: 'auto',
                    '& .MuiListItemIcon-root': {
                      fontSize: '1rem',
                      minWidth: 28
                    }
                  }}
                  role="presentation"
                  onClick={drawerToggler(false)}
                  onKeyDown={drawerToggler(false)}
                >
                  <List>
                    {
                      isLoggedIn ? (
                      <Link style={{ textDecoration: 'none' }} href="/lp-analysis" target="_blank">
                        <ListItemButton component="span">
                          <ListItemIcon>
                            <LineOutlined />
                          </ListItemIcon>
                          <ListItemText primary="Go to platform" primaryTypographyProps={{ variant: 'h6', color: 'text.primary' }} />
                        </ListItemButton>
                      </Link>
                      )
                      : (
                      <Link style={{ textDecoration: 'none' }} href="/login" target="_blank">
                        <ListItemButton component="span">
                          <ListItemIcon>
                            <LineOutlined />
                          </ListItemIcon>
                          <ListItemText primary="Sign In" primaryTypographyProps={{ variant: 'h6', color: 'text.primary' }} />
                        </ListItemButton>
                      </Link>
                      )

                    }
                    

                    <Link
                      style={{ textDecoration: 'none' }}
                      href="/pricing"
                      target="_blank"
                    >
                      <ListItemButton component="span">
                        <ListItemIcon>
                          <LineOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Pricing" primaryTypographyProps={{ variant: 'h6', color: 'text.primary' }} />
                      </ListItemButton>
                    </Link>
                    {/* <Link style={{ textDecoration: 'none' }} href="https://blog.askpot.com" target="_blank">
                      <ListItemButton component="span">
                        <ListItemIcon>
                          <LineOutlined />
                        </ListItemIcon>
                        <ListItemText primary="Blog" primaryTypographyProps={{ variant: 'h6', color: 'text.primary' }} />
                      </ListItemButton>
                    </Link> */}


                  </List>
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </ElevationScroll>
  );
};

Header.propTypes = {
  handleDrawerOpen: PropTypes.func,
  layout: PropTypes.string
};

export default Header;
