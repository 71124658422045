import { SET_REVIEWS_ANALYSIS_RESULTS } from './actions';


const initialState = {

  };
  
  const reviewsReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_REVIEWS_ANALYSIS_RESULTS: {
        return {
          ...state,
          analysisData: action.payload, // Assuming you want the entire overview_result object
          url: action.payload.url, // Assuming the company name is the URL
        };
      }
      default:
        return state;
    }
  };

  
  export default reviewsReducer;