import { ADD_TO_COMPARISON, REMOVE_FROM_COMPARISON } from './actions';

const initialState = {
  comparisonList: [],
};

const addToComparisonReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_TO_COMPARISON:
      return {
        ...state,
        comparisonList: [...state.comparisonList, action.payload],
      };
    case REMOVE_FROM_COMPARISON: {
      const updatedComparisonList = state.comparisonList.filter(
        item => item !== action.payload // Remove item with the exact name
      );
      return {
        ...state,
        comparisonList: updatedComparisonList,
      };
    }
    default:
      return state;
  }
};

export default addToComparisonReducer;