// import { useMemo } from 'react';

// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
import Search from './Search';
// import Message from './Message';
import Profile from './Profile';
// import Notification from './Notification';
import MobileSection from './MobileSection';
// import HistoryButton from './HistoryButton';
// import ErrorAlert from 'sections/lp-analysis/ErrorAlert';
// import MegaMenuSection from './MegaMenuSection';
// import { useLocation } from 'react-router-dom';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
  // const location = useLocation();
  // const isLpAnalysisPage = location.pathname === '/lp-analysis';
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const megaMenu = useMemo(() => <MegaMenuSection />, []);

  return (
    <>
      {!matchesXs && <Search />}
      {/* {!matchesXs && megaMenu} */}
      {/* {!matchesXs && <ErrorAlert/>} */}
      {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}

      {/* <Notification /> */}
      {/* <Message /> */}
      {/* {!matchesXs&& isLpAnalysisPage && <HistoryButton/>} */}
      {!matchesXs && <Profile/>}
      {matchesXs && <MobileSection />}
    </>
  );
};

export default HeaderContent;
