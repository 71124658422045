import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, ButtonBase, CardContent, ClickAwayListener, Grid, Paper, Popper, Stack, Button, ListItem,
  // Tab, Tabs,
   Tooltip, Typography } from '@mui/material';
import { openDrawer } from 'store/reducers/menu';

// project import
// import ProfileTab from './ProfileTab';
// import SettingTab from './SettingTab';
import Avatar from 'components/@extended/Avatar';
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';
// import IconButton from 'components/@extended/IconButton';
import useAuth from 'hooks/useAuth';

import { useDispatch, useSelector } from 'react-redux';

// assets
import avatar1 from 'assets/images/users/avatar-1.png';
import { LogoutOutlined, 
  // SettingOutlined, UserOutlined 
} from '@ant-design/icons';
import BillingManagementButton from 'sections/premiumBlock/BillingManagementButton';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

// function a11yProps(index) {
//   return {
//     id: `profile-tab-${index}`,
//     'aria-controls': `profile-tabpanel-${index}`
//   };
// }

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

const Profile = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  // const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const menu = useSelector((state) => state.menu)
  const drawerOpen = menu.drawerOpen
  const { logout, user } = useAuth();
  const dispatch = useDispatch();
  const handleLogout = async () => {
    try {
      await logout();
      navigate(`/login`, {
        state: {
          from: ''
        }
      });
    } catch (err) {
      console.error(err);
    }
  };

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
   
    setOpen((prevOpen) => !prevOpen);
  };

  const handleToggleMinimized = () => {
    
    dispatch((openDrawer({ drawerOpen: !drawerOpen })))
    setOpen((prevOpen) => !prevOpen);
  }
 
  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  // const [value, setValue] = useState(0);

  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const iconBackColorOpen = theme.palette.mode === 'dark' ? 'grey.200' : 'grey.300';

  return (
    <Box sx={{ flexShrink: 0, ml: 0.0}}>
      {drawerOpen ?

     
      <ButtonBase
        
        sx={{
          
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
            
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
     
          <Avatar alt="profile user" src={user?.avatar ? user?.avatar : avatar1} size="xs" />
          <Typography variant="subtitle2">
            {user?.name && user.name.length > 15 ? `${user.name.slice(0, 15)}...` : user?.name}
            </Typography>
        </Stack>
      </ButtonBase>
       : 
       <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : 'transparent',
          borderRadius: 1,
          '&:hover': { bgcolor: theme.palette.mode === 'dark' ? 'secondary.light' : 'secondary.lighter' },
          '&:focus-visible': {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2
          }
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggleMinimized}
      >
        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }}>
     
          <Avatar alt="profile user" src={user?.avatar ? user?.avatar : avatar1} size="xs" />
          {/* <Typography variant="subtitle1">{user?.name}</Typography> */}
        </Stack>
      </ButtonBase>
       }
      <Popper
        placement="bottom-start"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 9]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position="top-right" in={open} {...TransitionProps}>
            <Paper
              sx={{
                position: 'absolute',
                top:-140,
                boxShadow: theme.customShadows.z1,
                // zIndex:9999,
                width: 250,
                minWidth: 200,
                maxWidth: 250,
                overflow: 'auto',
                [theme.breakpoints.down('md')]: {
                  maxWidth: 222
                }
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={false} >
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid container justifyContent="space-between" alignItems="center">
                      <Grid item>
                        <Stack direction="row" spacing={1.25} alignItems="center">
                          <Avatar alt="profile user" src={user?.avatar ? user?.avatar : avatar1} sx={{ width: 26, height: 26 }} />
                          <Stack>
                            <Typography variant="body1">{user?.name}</Typography>
                            <Typography variant="body2">{user?.email}</Typography>

                          </Stack>
                        </Stack>
                      </Grid>
                      <Grid item>
                          <ListItem  divider>
                           <BillingManagementButton/>
                          </ListItem>
                        <Tooltip title="Logout">
                          
                          <Button endIcon={<LogoutOutlined />} size="small" sx={{ color: 'text.primary', mt:2 }} onClick={handleLogout}>
                          Logout
                          </Button>
                          
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    {/* <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs">
                      <Tab
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textTransform: 'capitalize'
                        }}
                        icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                        label="Profile"
                        {...a11yProps(0)}
                      />
                      <Tab
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                          textTransform: 'capitalize'
                        }}
                        icon={<SettingOutlined style={{ marginBottom: 0, marginRight: '10px' }} />}
                        label="Setting"
                        {...a11yProps(1)}
                      />
                    </Tabs> */}
                  </Box>
                  {/* <TabPanel value={value} index={0} dir={theme.direction}>
                    <ProfileTab handleLogout={handleLogout} />
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <SettingTab />
                  </TabPanel> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
};

export default Profile;
