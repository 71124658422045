// chat constant
export const text1 = `How can i make height of element dependent on height of other element in mui`;
export const text2 = `In Material-UI (mui),  CSS style object.`;
export const text3 = `debug it please`;
export const text4 = `Based on the error message provided in the initial question, the ChatMessageAction component is being imported and used in another file, but it cannot be found because it is not defined.`;

export const text5 = `Hey man`;
export const text6 = `Hi, Wats up?`;
export const text7 = `Need your minute. are you available?`;
export const text8 = `Sure. Let's meet.`;


// query history
export const queries_history = [
  {id: 1, user_text: [text1, text2], ai_text:[text1, text2]},
  {id: 2, user_text: [text1, text2], ai_text:[text1, text2]},
  {id: 3, user_text: [text1, text2], ai_text:[text1, text2]},
  {id: 4, user_text: [text1, text2], ai_text:[text1, text2]},
  {id: 5, user_text: [text1, text2], ai_text:[text1, text2]},
  {id: 6, user_text: [text1, text2], ai_text:[text1, text2]},
]

export const queries = [
  {  
    id:1, 
    name: 'GPT',
    label: 'GPT'
  },
  {  
    id:2, 
    name: 'GPT',
    label: 'March - Cashflow'
  },
  {  
    id:3, 
    name: 'GPT',
    label: 'March - Reports'
  },
  {  
    id:3, 
    name: 'GPT',
    label: '2022 - Taxes'
  },
]



// user list
export const users = [
  {
    id: 1,
    name: 'GPT',
    company: 'ABC Pvt Ltd',
    role: 'Sr. Customer Manager',
    work_email: 'alene_work@company.com',
    personal_email: 'alene@company.com',
    work_phone: '380-293-0177',
    personal_phone: '380-293-0177',
    location: 'Port Narcos',
    avatar: 'avatar-10.png',
    status: 'Technical Department',
    lastMessage: '2 hours',
    birthdayText: 'happy Birthday Alene',
    unReadChatCount: 2,
    online_status: 'available'
  },
  {
    id: 2,
    name: 'Keefe',
    company: 'ABC Pvt Ltd',
    role: 'Dynamic Operations Officer',
    work_email: 'keefe_work@gmil.com',
    personal_email: 'keefe@gmil.com',
    work_phone: '253-418-5940',
    personal_phone: '253-418-5940',
    location: 'Afghanistan',
    avatar: 'avatar-2.png',
    status: 'Team Worker',
    lastMessage: '3 hours',
    birthdayText: 'happy Birthday Keefe',
    unReadChatCount: 3,
    online_status: 'available'
  },
  {
    id: 3,
    name: 'Lazaro',
    company: 'ABC Pvt Ltd',
    role: 'Resource Investigator',
    work_email: 'lazaro_work@gmil.com',
    personal_email: 'lazaro@gmil.com',
    work_phone: '283-029-1364',
    personal_phone: '283-029-1364',
    location: 'Albania',
    avatar: 'avatar-3.png',
    status: 'Technical Department',
    lastMessage: '2 days',
    birthdayText: 'happy Birthday Lazaro',
    unReadChatCount: 1,
    online_status: 'available'
  },
  {
    id: 4,
    name: 'Hazle',
    company: 'ABC Pvt Ltd',
    role: 'Teamworker',
    work_email: 'hazle_work@gmil.com',
    personal_email: 'hazle@gmil.com',
    work_phone: '380-293-0177',
    personal_phone: '380-293-0177',
    location: 'Algeria',
    avatar: 'avatar-4.png',
    status: 'Technical Department',
    lastMessage: '2 days',
    birthdayText: 'happy Birthday Hazle',
    unReadChatCount: 0,
    online_status: 'do_not_disturb'
  },
  {
    id: 5,
    name: 'Herman Essertg',
    company: 'ABC Pvt Ltd',
    role: 'Co-ordinator',
    work_email: 'herman_essertg_work@gmil.com',
    personal_email: 'herman_essertg@gmil.com',
    work_phone: '253-418-5940',
    personal_phone: '253-418-5940',
    location: 'Andorra',
    avatar: 'avatar-5.png',
    status: 'Technical Department',
    lastMessage: '30 min',
    birthdayText: 'happy Birthday Herman',
    unReadChatCount: 0,
    online_status: 'do_not_disturb'
  },
  {
    id: 6,
    name: 'Wilhelmine Durrg',
    company: 'ABC Pvt Ltd',
    role: 'Monitor Evaluator',
    work_email: 'wilhelmine_durrg_work@gmil.com',
    personal_email: 'wilhelmine_durrg@gmil.com',
    work_phone: '380-293-0177',
    personal_phone: '380-293-0177',
    location: 'Angola',
    avatar: 'avatar-6.png',
    status: 'Monitor Evaluator',
    lastMessage: '2h ago',
    birthdayText: 'happy Birthday Wilhelmine',
    unReadChatCount: 0,
    online_status: 'available'
  },
  {
    id: 7,
    name: 'Agilulf Fuxg',
    company: 'ABC Pvt Ltd',
    role: 'Specialist',
    work_email: 'agilulf_fuxg_work@gmil.com',
    personal_email: 'agilulf_fuxg@gmil.com',
    work_phone: '253-418-5940',
    personal_phone: '253-418-5940',
    location: 'Antigua and Barbuda',
    avatar: 'avatar-7.png',
    status: 'Specialist',
    lastMessage: '2h ago',
    birthdayText: 'happy Birthday Agilulf',
    unReadChatCount: 0,
    online_status: 'available'
  },
  {
    id: 8,
    name: 'Adaline Bergfalks',
    company: 'ABC Pvt Ltd',
    role: 'Shaper',
    work_email: 'adaline_bergfalks_work@gmil.com',
    personal_email: 'adaline_bergfalks@gmil.com',
    work_phone: '253-118-5940',
    personal_phone: '253-118-5940',
    location: 'Argentina',
    avatar: 'avatar-8.png',
    status: 'Shaper',
    lastMessage: '2h ago',
    birthdayText: 'happy Birthday Adaline',
    unReadChatCount: 0,
    online_status: 'offline'
  },
  {
    id: 9,
    name: 'Eadwulf Beckete',
    company: 'ABC Pvt Ltd',
    role: 'Implementer',
    work_email: 'eadwulf_beckete_work@gmil.com',
    personal_email: 'eadwulf_beckete@gmil.com',
    work_phone: '153-418-5940',
    personal_phone: '153-418-5940',
    location: 'Armenia',
    avatar: 'avatar-9.png',
    status: 'Implementer',
    lastMessage: '1 day ago',
    birthdayText: 'happy Birthday Eadwulf',
    unReadChatCount: 0,
    online_status: 'offline'
  },
  {
    id: 10,
    name: 'Midas',
    company: 'ABC Pvt Ltd',
    role: 'Leader',
    work_email: 'midas_work@gmil.com',
    personal_email: 'midas@gmil.com',
    work_phone: '252-418-5940',
    personal_phone: '252-418-5940',
    location: 'Australia',
    avatar: 'avatar-2.png',
    status: 'Leader',
    lastMessage: '1 day ago',
    birthdayText: 'happy Birthday Midas',
    unReadChatCount: 0,
    online_status: 'offline'
  },
  {
    id: 11,
    name: 'Uranus',
    company: 'ABC Pvt Ltd',
    role: 'Facilitator',
    work_email: 'uranus_work@gmil.com',
    personal_email: 'uranus@gmil.com',
    work_phone: '253-218-5940',
    personal_phone: '253-218-5940',
    location: 'Austria',
    avatar: 'avatar-3.png',
    status: 'Facilitator',
    lastMessage: '2 days ago',
    birthdayText: 'happy Birthday Uranus',
    unReadChatCount: 0,
    online_status: 'available'
  },
  {
    id: 12,
    name: 'Peahen',
    company: 'ABC Pvt Ltd',
    role: 'Coach',
    work_email: 'peahen_work@gmil.com',
    personal_email: 'peahen@gmil.com',
    work_phone: '253-418-1940',
    personal_phone: '253-418-1940',
    location: 'Azerbaijan',
    avatar: 'avatar-4.png',
    status: 'One of the Graces.',
    lastMessage: '3 days ago',
    birthdayText: 'happy Birthday Peahen',
    unReadChatCount: 0,
    online_status: 'do_not_disturb'
  },
  {
    id: 13,
    name: 'Menelaus',
    company: 'ABC Pvt Ltd',
    role: 'Facilitator',
    work_email: 'menelaus_work@gmil.com',
    personal_email: 'menelaus@gmil.com',
    work_phone: '053-418-5940',
    personal_phone: '053-418-5940',
    location: 'Bahamas',
    avatar: 'avatar-9.png',
    status: 'To stay',
    lastMessage: '6 days ago',
    birthdayText: 'happy Birthday Menelaus',
    unReadChatCount: 0,
    online_status: 'offline'
  }
];

// chat history
export const chatHistories = [
  { id: 1, from: 'User1', to: 'GPT', text: text1, time: '11:23 AM' },
  { id: 2, from: 'GPT', to: 'User1', text: text2, time: '11:23 AM' },
  { id: 3, from: 'User1', to: 'GPT', text: text3, time: '11:23 AM' },
  { id: 4, from: 'GPT', to: 'User1', text: text4, time: '11:23 AM' },

  { id: 5, from: 'User1', to: 'GPT', text: text5, time: '11:24 AM' },
  { id: 6, from: 'GPsT', to: 'User1', text: text6, time: '11:24 AM' },
  { id: 7, from: 'User1', to: 'GPT', text: text7, time: '11:24 AM' },
  { id: 8, from: 'GPT', to: 'User1', text: text8, time: '11:24 AM' },

  { id: 9, from: 'User1', to: 'Lazaro', text: text1, time: '11:25 AM' },
  { id: 10, from: 'Lazaro', to: 'User1', text: text2, time: '11:25 AM' },
  { id: 11, from: 'User1', to: 'Lazaro', text: text3, time: '11:25 AM' },
  { id: 12, from: 'Lazaro', to: 'User1', text: text4, time: '11:25 AM' },

  { id: 13, from: 'User1', to: 'Hazle', text: text5, time: '11:26 AM' },
  { id: 14, from: 'Hazle', to: 'User1', text: text6, time: '11:26 AM' },
  { id: 15, from: 'User1', to: 'Hazle', text: text7, time: '11:26 AM' },
  { id: 16, from: 'Hazle', to: 'User1', text: text8, time: '11:26 AM' },

  { id: 17, from: 'User1', to: 'Herman Essertg', text: text1, time: '11:27 AM' },
  { id: 18, from: 'Herman Essertg', to: 'User1', text: text2, time: '11:27 AM' },
  { id: 19, from: 'User1', to: 'Herman Essertg', text: text3, time: '11:27 AM' },
  { id: 20, from: 'Herman Essertg', to: 'User1', text: text4, time: '11:27 AM' },

  { id: 21, from: 'User1', to: 'Wilhelmine Durrg', text: text5, time: '11:28 AM' },
  { id: 22, from: 'Wilhelmine Durrg', to: 'User1', text: text6, time: '11:28 AM' },
  { id: 23, from: 'User1', to: 'Wilhelmine Durrg', text: text7, time: '11:28 AM' },
  { id: 24, from: 'Wilhelmine Durrg', to: 'User1', text: text8, time: '11:28 AM' },

  { id: 25, from: 'User1', to: 'Agilulf Fuxg', text: text1, time: '11:29 AM' },
  { id: 26, from: 'Agilulf Fuxg', to: 'User1', text: text2, time: '11:29 AM' },
  { id: 27, from: 'User1', to: 'Agilulf Fuxg', text: text3, time: '11:29 AM' },
  { id: 28, from: 'Agilulf Fuxg', to: 'User1', text: text4, time: '11:29 AM' },

  { id: 29, from: 'User1', to: 'Adaline Bergfalks', text: text5, time: '11:30 AM' },
  { id: 30, from: 'Adaline Bergfalks', to: 'User1', text: text6, time: '11:30 AM' },
  { id: 31, from: 'User1', to: 'Adaline Bergfalks', text: text7, time: '11:30 AM' },
  { id: 32, from: 'Adaline Bergfalks', to: 'User1', text: text8, time: '11:30 AM' },

  { id: 33, from: 'User1', to: 'Eadwulf Beckete', text: text1, time: '11:31 AM' },
  { id: 34, from: 'Eadwulf Beckete', to: 'User1', text: text2, time: '11:31 AM' },
  { id: 35, from: 'User1', to: 'Eadwulf Beckete', text: text3, time: '11:31 AM' },
  { id: 36, from: 'Eadwulf Beckete', to: 'User1', text: text4, time: '11:31 AM' },

  { id: 37, from: 'User1', to: 'Midas', text: text5, time: '11:32 AM' },
  { id: 38, from: 'Midas', to: 'User1', text: text6, time: '11:32 AM' },
  { id: 39, from: 'User1', to: 'Midas', text: text7, time: '11:32 AM' },
  { id: 40, from: 'Midas', to: 'User1', text: text8, time: '11:32 AM' },

  { id: 41, from: 'User1', to: 'Uranus', text: text1, time: '11:33 AM' },
  { id: 42, from: 'Uranus', to: 'User1', text: text2, time: '11:33 AM' },
  { id: 43, from: 'User1', to: 'Uranus', text: text3, time: '11:33 AM' },
  { id: 44, from: 'Uranus', to: 'User1', text: text4, time: '11:33 AM' },

  { id: 45, from: 'User1', to: 'Peahen', text: text5, time: '11:34 AM' },
  { id: 46, from: 'Peahen', to: 'User1', text: text6, time: '11:34 AM' },
  { id: 47, from: 'User1', to: 'Peahen', text: text7, time: '11:34 AM' },
  { id: 48, from: 'Peahen', to: 'User1', text: text8, time: '11:34 AM' },

  { id: 49, from: 'User1', to: 'Menelaus', text: text1, time: '11:35 AM' },
  { id: 50, from: 'Menelaus', to: 'User1', text: text2, time: '11:35 AM' },
  { id: 51, from: 'User1', to: 'Menelaus', text: text3, time: '11:35 AM' },
  { id: 52, from: 'Menelaus', to: 'User1', text: text4, time: '11:35 AM' }
];
