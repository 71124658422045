// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  // ChromeOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined,
  UserOutlined,
  SettingOutlined,
  AimOutlined,
  RadarChartOutlined,
  StarOutlined
} from '@ant-design/icons';

// icons
const icons = {
  RadarChartOutlined,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  UserOutlined,
  SettingOutlined,
  AimOutlined,
  StarOutlined
};
// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other = {
  id: 'other',
  // title: <FormattedMessage id="others" />,
  type: 'group',
  children: [
    // {
    //   id: 'sample-page',
    //   title: <FormattedMessage id="sample-page" />,
    //   type: 'item',
    //   url: '/sample-page',
    //   icon: icons.ChromeOutlined,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'askpot-chat',
    //   title: <FormattedMessage id="askpot-chat" />,
    //   type: 'item',
    //   url: '/main',
    //   icon: icons.DeploymentUnitOutlined,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'lp-comparer',
    //   title: <FormattedMessage id="lp-comparer" />,
    //   type: 'item',
    //   url: '/lp',
    //   icon: icons.DeploymentUnitOutlined,
    //   breadcrumbs: false
    // },
    {
      id: 'lp-analysis',
      title: <FormattedMessage id="lp-analysis" />,
      type: 'item',
      url: '/lp-analysis',
      icon: icons.RadarChartOutlined,
      breadcrumbs: false
    },
    {
      id: 'competitors-history',
      title: <FormattedMessage id="competitors-history" />,
      type: 'item',
      url: '/competitors-history',
      icon: icons.AimOutlined,
      breadcrumbs: false
    },
    {
      id: 'reviews',
      title: <FormattedMessage id="reviews" />,
      type: 'item',
      url: '/reviews',
      icon: icons.StarOutlined,
      breadcrumbs: false
    },
    // {
    //   id: 'profile',
    //   title: <FormattedMessage id="profile" />,
    //   type: 'collapse',
    //   icon: icons.UserOutlined,
    //   children: [
        // {
        //   id: 'user-profile',
        //   title: <FormattedMessage id="user-profile" />,
        //   type: 'item',
        //   url: '/profiles/user/personal',
        //   icon: icons.SettingOutlined,
        //   breadcrumbs: false
        // }
        
      // ]
    // }
  ]
};

export default other;
