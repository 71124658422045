import { ADD_COMPARISON_ANSWER } from './actions';

const initialState = {
  response: [],
};

const comparisonReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_COMPARISON_ANSWER:
      return {
        ...state,
        response: [...state.response, action.payload],
      };
    default:
      return state;
  }
};
   
export default comparisonReducer;