import { SET_LOADING } from "./actions";
import { SET_LOADING_SWOT } from "./actions";
import { SET_LOADING_COMPETITORS } from "./actions";
import { SET_LOADING_COMPETITORS_RESULTS } from "./actions";


const initialState = false;

const loadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_LOADING:
      return {loadingAnswer: action.payload}
    case SET_LOADING_SWOT:
      return {loadingSWOT: action.payload}
    case SET_LOADING_COMPETITORS:
      return {loadingCompetitors: action.payload}
    case SET_LOADING_COMPETITORS_RESULTS:
      return {loadingCompetitorsResults: action.payload}

    default:
      return state;
  }
};

export default loadingReducer;