import React from 'react';
import { ListItemButton, Typography } from '@mui/material';
// import createPortalSession from 'services/createPortalSessionStripe';
import { Link } from 'react-router-dom';


const BillingManagementButton = () => {



    return (
        <ListItemButton size='small' component={Link} to="/profiles/user/payment" id="manage-billing-button" aria-label="support">
            <Typography variant='body2' color='secondary'>
                 Manage Subscription
            </Typography>
            
        </ListItemButton>
    );
};

export default BillingManagementButton;

