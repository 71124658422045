import { SET_PUBLIC_DATA } from './actions';


const initialState = {
    analysisData: {},
    extendedData: {},
    companyName: '',
  };
  
  const publicDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PUBLIC_DATA: {
        const { overview_result, ...rest } = action.payload; // Use curly braces to create a block scope
        return {
          ...state,
          analysisData: overview_result ? overview_result : {}, // Assuming you want the entire overview_result object
          extendedData: rest, // Everything else
          companyName: action.payload.url, // Assuming the company name is the URL
        };
      }
      default:
        return state;
    }
  };

  
  export default publicDataReducer;