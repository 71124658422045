// project import
import other from './other';
// import applications from './applications';


// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [other]
};

export default menuItems;
