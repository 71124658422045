import { ADD_ERROR_MESSAGE, REMOVE_ERROR_MESSAGE } from './actions';

const initialState = {
    errorList: [],
};

const addErrorMessage = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ERROR_MESSAGE:
            return {
                ...state,
                errorList: [...state.errorList, action.payload],
            };
        case REMOVE_ERROR_MESSAGE:
            return {
                ...state,
                errorList: state.errorList.slice(0, -1), // Remove the last error
            };
        default:
            return state;
    }
};

export default addErrorMessage;