import { SET_HISTORY_DRAWER_STATE, SET_HISTORY_DATA, SET_HISTORY_RESULT } from './actions';

const initialState = {
  isOpen: false,
  responseData: [], // Initial response data is an empty array
  historyResult: null
};

const historyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_HISTORY_DRAWER_STATE:
      return {
        ...state,
        isOpen: !state.isOpen, // Toggle the isOpen state
      };
    case SET_HISTORY_DATA:
      return {
        ...state,
        responseData: action.data, // Set the response data
      };
    case SET_HISTORY_RESULT:
        return {
          ...state,
          historyResult: action.data  // Set history response data
        }
    default:
      return state;
  }
};

export default historyReducer;