

import { SET_PRO_ACCOUNT_DATA } from './actions';


const initialState = {
    monthly_credits: 0,
    remaining_credits: 0,
    plan: '',
  };
  
  const proAccountReducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_PRO_ACCOUNT_DATA:
        return {
          ...state,
          ...action.payload,
        };
      default:
        return state;
    }
  };
  
  export default proAccountReducer;