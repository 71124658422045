


import { SET_CURRENT_TASK_ID } from './actions';

const initialState = {
  task_id: null,
};

const currentTaskReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_TASK_ID:
      return {
        ...state,
        task_id: action.payload, // Toggle the isOpen state
      };
    default:
      return state;
  }
};

export default currentTaskReducer;