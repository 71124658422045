import { SET_PUBLIC_LIST_VALUES } from './actions';


const initialState = {
    currentPageData: {},
    totalPages: 1,
    currentPage: 1,
  };

const publicDataListReducer = (state = initialState, action) => {
switch (action.type) {
    case SET_PUBLIC_LIST_VALUES:
    return {
        ...state,
        currentPageData: action.payload.currentPageData.websites,
        totalPages: action.payload.currentPageData.total_pages,
        currentPage: action.payload.currentPageData.current_page,
    };
    default:
    return state;
}
};
  
  export default publicDataListReducer;