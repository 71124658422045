
import { ADD_PARAMETER, UPDATE_PARAMETER, DELETE_PARAMETER, RESTORE_DEFAULT_PARAMETERS, DESELECT_ALL_PARAMETERS } from './actions';


const initialState = {
  parameters: [
    { id: 0, Parameter: 'Service Name', Description: 'ONLY NAMES' },
    { id: 1, Parameter: 'Industry', Description: 'Industry and subIndustry of service, eg Gaming, Game Analytics' },
    { id: 2, Parameter: 'Main Value Proposition', Description: 'Describe Main Value proposition of service. 2 sentences' },
    { id: 3, Parameter: 'Description', Description: 'Give indepth description of service in one sentence. up to 2 sentences' },
    { id: 4, Parameter: 'Key Features', Description: 'Describe Key features up to 2 sentences, comma separated description' },
    { id: 5, Parameter: 'Use Cases', Description: 'For what this service can be used? 2 sentences' },
    { id: 6, Parameter: 'Competitive advantages', Description: 'What competitive advantages this service have? 2 sentences' },
    { id: 7, Parameter: 'Pricing', Description: 'Show exact price and payment methods as plain text, if no info than put N/A' },
    { id: 8, Parameter: 'Strengths', Description: 'What strength do they have?' },
    { id: 9, Parameter: 'Positioning', Description: 'Give a broad description. 2 sentences' },
    { id: 10, Parameter: 'Audience', Description: 'Broad audience description. 2 sentences' },
    { id: 11, Parameter: 'CTA', Description: 'Which call to actions they have?' },
    { id: 12, Parameter: 'Tone of Voice', Description: 'Explain Tone of voice of service with associated adjectives only' },
    { id: 13, Parameter: 'Brand Positioning', Description: 'How brand positions itself?' },
    { id: 14, Parameter: 'Differentiation', Description: 'How they differ from competitors?' },
    { id: 15, Parameter: 'Trust Factors', Description: 'What kind of trust factors they have?' },
    { id: 16, Parameter: 'Social Proof', Description: 'What kind of social proof they have?' },
  ],
};


  const comparisonParamsReducer = (state = initialState, action) => {
    switch (action.type) {
      case ADD_PARAMETER:
        return {
          ...state,
          parameters: [...state.parameters, action.payload],
        };
  
      case UPDATE_PARAMETER:
        return {
          ...state,
          parameters: state.parameters.map((param) =>
            param.Parameter === action.payload.Parameter
              ? { ...param, ...action.payload }
              : param
          ),
        };
  
      case DELETE_PARAMETER:
        return {
          ...state,
          parameters: state.parameters.filter(
            (param) => param.Parameter !== action.payload.Parameter
          ),
        };

      case RESTORE_DEFAULT_PARAMETERS:
        return {
          ...state,
          parameters: initialState.parameters, // Restore to the initial state
        };

      case DESELECT_ALL_PARAMETERS:
        return {
          ...state,
          parameters: [], // Clear the parameter list
        };

      default:
        return state;
    }
  };

  export default comparisonParamsReducer;