import { SET_CURRENT_URL } from './actions'


const initialState = {
    currentURL: ''
}

const currentURLReducer = (state = initialState, action) => {
    switch(action.type) {
        case SET_CURRENT_URL:
            return {
                ...state, 
                currentURL: action.payload // Assuming you have a payload property in your action
            };
        default:
            return state; // Don't forget to handle the default case
    }
}

export default currentURLReducer;